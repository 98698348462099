import Vue from "vue";
import Vuex from "vuex";
import api from "@/api/api.ts";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        stateApp: ["waiting", "approved", "declined"],
        datas: {},
        maindatas: {},
        users: [],
        clients: [],
        error: "",
        commentcount: 2,
        isLoadingUser: true,
        item: {},
        limitheight: false,
        switchInterface: 0,
        filterType: "all",
        tagsList: [],
        tagSearchModel: [],
        tagCounter: [],
        googleLoader: false,
        tagbar: false
    },
    mutations: {
        SET_DATAS: (state, datas) => {
            state.datas = datas;
            state.maindatas = datas;
        },
        SET_ALL_TAGS: (state, tags) => {
            // @ts-ignore
            let taglist = [];
            for (let key in tags) {
                // @ts-ignore
                taglist = taglist.concat(tags[key]);
            }
            taglist = taglist
                .map(el => {
                    return el.tags;
                })
                .filter(item => item.length);
            // @ts-ignore
            let newTagList = [];
            for (let index = 0; index < taglist.length; index++) {
                // @ts-ignore
                newTagList = newTagList.concat(taglist[index]);
            }
            // @ts-ignore
            state.tagsList = newTagList;
        },
        ADD_NEW_TAG: (state, data) => {
            // @ts-ignore
            state.tagsList.push(data);
        },
        SEARCH_DATAS: (state, input) => {
            const newData = state.maindatas;
            const arr = {};
            for (let key in newData) {
                // @ts-ignore
                arr[key] = newData[key].filter(el => {
                    return el.name.toLowerCase().includes(input);
                });
            }
            state.datas = arr;
        },
        SET_USERS: (state, users) => {
            state.users = users;
        },
        SET_CLIENTS: (state, clients) => {
            state.clients = clients;
        },

        SET_LOADING_USER: (state, load) => {
            state.isLoadingUser = load;
        },
        SET_ITEM: (state, item) => {
            state.item = item;
        },
        STATUS_CREATIVE: (state, data) => {
            const pathfrom = data.movefrom;
            const pathto = data.moveto;
            // @ts-ignore
            const datasload = state.datas[pathfrom];
            // @ts-ignore
            const movingCreative = datasload.filter(elem => elem.id == data.id)[0];
            movingCreative.status = data.moveto;
            // @ts-ignore
            state.datas[pathfrom] = datasload.filter(elem => elem.id != data.id);
            // @ts-ignore
            state.datas[pathto].unshift(movingCreative);
        },
        SET_IMAGE: (state, imageData) => {
            const status = imageData.status;
            // @ts-ignore
            state.datas[status].map(el => {
                if (el.id == imageData.id) {
                    // @ts-ignore
                    el.imageBlob = imageData.img;
                }
            });
        },
        SET_LIMIT_HEIGHT: (state, boolka) => {
            state.limitheight = boolka;
        },
        SET_COMMENTS: (state, comments) => {
            // @ts-ignore
            state.datas[comments.status].map(el => {
                if (el.id == comments.id) {
                    el.commentsLength = comments.data.length;
                    el.commentsList = comments.data;
                }
            });
        },
        SET_INTERFACE: (state, boolka) => {
            state.switchInterface = boolka;
        },
        SET_FILTER_TYPE: (state, type) => {
            state.filterType = type;
        },
        SET_DELETE_USER: (state, id) => {
            // @ts-ignore
            state.users = state.users.filter(el => el.id != id);
        },
        SET_SEARCH_TAG: (state, data) => {
            state.tagSearchModel = data;
        },
        SET_SEARCH_TAG_PUSH: (state, item) => {
            // @ts-ignore
            state.tagSearchModel.push(item);
        },
        SET_SEARCH_TAG_DELETE: (state, item) => {
            // @ts-ignore
            state.tagSearchModel.splice(state.tagSearchModel.indexOf(item), 1);
        },
        SET_CHECKLIST_TAGS: state => {
            const newData = state.maindatas;
            const arr = {};
            for (let key in newData) {
                // @ts-ignore
                arr[key] = newData[key].filter(el => {
                    // @ts-ignore
                    return state.tagSearchModel.every(elem => el.tags.includes(elem));
                });
            }
            // @ts-ignore
            localStorage.setItem("filterTag", JSON.stringify(state.tagSearchModel));
            state.datas = arr;
            console.log(state.datas);
        },
        SET_COUNTER_STATE_TAGS: (state, data) => {
            const newData = state.maindatas;
            const arr = {};
            for (let key in newData) {
                // @ts-ignore
                arr[key] = newData[key].filter(el => {
                    // @ts-ignore
                    return state.tagSearchModel.every(elem => el.tags.includes(elem));
                });
            }
            return "arr";
        },
        SET_TAG_COUNTER: (state, item) => {
            // @ts-ignore
            state.tagCounter = item;
        },
        SET_NEW_TAGLIST_ITEM: (state, payload) => {
            let find = {};
            for (const key in state.datas) {
                // @ts-ignore
                const item = state.datas[key].find(x => x.id === payload.id);
                if (item) {
                    // @ts-ignore
                    const index = state.datas[key].indexOf(item);
                    find = item;
                    // @ts-ignore
                    state.datas[key][index].tags = payload.tags;
                }
            }
        },
        SET_GOOGLE_LOADER: (state, bool) => {
            state.googleLoader = bool;
        },
        SET_TAGS_BAR: (state, bool) => {
            state.tagbar = bool;
        }
    },
    actions: {
        ADD_CLIENT({ commit }, sendData) {
            return api.post(`client`, sendData);
        },
        GET_DATAS_FROM_API({ commit }) {
            commit("SET_GOOGLE_LOADER", true);
            return api.get(`storage/?nocache=true`).then(elem => {
                if (elem.status == false) {
                    console.log("Ошибка загрузки", elem);
                    return elem;
                } else {
                    commit("SET_DATAS", elem);
                    commit("SET_ALL_TAGS", elem);
                    commit("SET_GOOGLE_LOADER", false);
                    return true;
                }
            });
        },
        LOAD_CLIENTS({ commit }) {
            if (window.location.hostname.includes("localhost") || window.location.hostname.includes("192.168")) {
                const data = [
                    { id: "65", name: "MediaBaingSourses" },
                    { id: "68", name: "LeroyMerlin", serviceLinks: ["https://dashboards.gomobile.ru/view/"] },
                    { id: "27", name: "justmelon", serviceLinks: ["https://creative.gomobile.ru/"] },
                    { id: "64", name: "CarInsurance" },
                    { id: "59", name: "newclient", serviceLinks: ["https://dashboards.gomobile.ru/view/"] },
                    { id: "35", name: "PamPamPam", serviceLinks: ["https://creative.gomobile.ru/"] },
                    { id: "58", name: "ClientOfGoodHope", serviceLinks: ["https://creative.gomobile.ru/"] },
                    { id: "36", name: "andorafolder", serviceLinks: ["https://creative.gomobile.ru/"] },
                    {
                        id: "63",
                        name: "KatyaFinance",
                        serviceLinks: ["https://creative.gomobile.ru/", "https://dashboards.gomobile.ru/view/"]
                    },
                    { id: "69", name: "Dominos", serviceLinks: ["https://dashboards.gomobile.ru/view/"] },
                    {
                        id: "67",
                        name: "Petstory",
                        serviceLinks: ["https://creative.gomobile.ru/", "https://dashboards.gomobile.ru/view/"]
                    },
                    { id: "71", name: "Derinat", serviceLinks: ["https://dashboards.gomobile.ru/view/"] },
                    { id: "66", name: "grechka", serviceLinks: ["https://creative.gomobile.ru/"] },
                    {
                        id: "23",
                        name: "SweetRoll",
                        serviceLinks: ["https://creative.gomobile.ru/", "https://dashboards.gomobile.ru/view/"]
                    },
                    { id: "72", name: "justmelonda" },
                    { id: "22", name: "hellothere" }
                ];
                commit("SET_CLIENTS", data);
                return false;
            } 
            return api.get(`https://client.entrance.gomobile.ru/api/v1/internal/client`).then(elem => {
                if (elem.status == false) {
                    /* commit("SET_ERROR", elem.data); */
                    return true;
                } else {
                    commit("SET_CLIENTS", elem.data);
                    return false;
                }
            });
        },
        GET_USERS_FROM_API({ commit }) {
            commit("SET_LOADING_USER", true);
            return api.get(`client/?nocache=true`).then(elem => {
                if (elem.status == false) {
                    if (elem.data.response.status == 401) {
                        return elem.data.response.status;
                    }
                    commit("SET_LOADING_USER", true);
                    return true;
                } else {
                    commit("SET_LOADING_USER", false);
                    commit("SET_USERS", elem);
                    return false;
                }
            });
        },
        GET_USER_BY_ID({ commit }, id) {
            return api.get(`client/${id}?nocache=true`);
        },
        DELETE_USER({ commit }, id) {
            return api.deleteMet(`client/${id}`).then(el => {
                if (el === "OK") {
                    commit("SET_DELETE_USER", id);
                }
            });
        },
        GET_IMAGE({ commit }, item) {
            return api.get(`storage/${item.id}`, "blob").then(el => {
                commit("SET_IMAGE", { id: item.id, status: item.status, img: el });
                return el;
            });
        },
        GET_COMMENTS_FROM_API({ commit }, id) {
            return api.get(`storage/comment/${id}?nocache=true`);
        },
        ADD_COMMENT({ commit }, comment) {
            return api.post(`storage/comment`, comment);
        },
        EDIT_COMMENT({ commit }, comment) {
            return api.put(`storage/comment`, comment);
        },
        ADD_FILE_COMMENT({ commit }, formData) {
            return api.post(`storage/`, formData);
        },
        DELETE_COMMENT({ commit }, comment) {
            return api.deleteMet(`storage/comment`, comment);
        },
        CHANGE_GROUPFILE_STATUS({ commit }, datas) {
            return api.put(`storage/moves`, datas).then(el => {
                console.log("moves result", el);
                return el;
            });
        },
        CHANGE_FILE_STATUS({ commit, dispatch }, data) {
            return api.put(`storage/move`, data).then(el => {
                if (el.data == "success") {
                    //-dispatch("GET_DATAS_FROM_API");
                    console.log("Moved!");
                    // this.loading = false;
                    return true;
                } else if (el.status == false) {
                    return false;
                }
            });
        },
        CHANGE_DATA_CLIENT({ commit }, datas) {
            return api.put(`client/${datas.id}`, datas.update);
        },

        loadCom(ctx, id) {
            ctx.commit("getCommentList", id);
        },
        ACTIVATE_CREATIVE({ commit }, id) {
            return api.post(`client/process/${id}`);
        },
        TAGS_UPDATE({ commit }, datas) {
            return api.put(`storage/tags`, datas);
        }
    },
    getters: {
        GET_STATE(state) {
            return state.stateApp;
        },
        GET_DATAS(state) {
            return state.datas;
        },
        GET_MAIN_DATAS(state) {
            return state.maindatas;
        },
        GET_USERS(state) {
            return state.users;
        },
        GET_CLIENTS(state) {
            return state.clients;
        },
        GET_LOADING_USER(state) {
            return state.isLoadingUser;
        },
        GET_ITEM(state) {
            return state.item;
        },
        GET_LIMIT_HEIGHT(state) {
            return state.limitheight;
        },
        GET_INTERFACE_TYPE(state) {
            return state.switchInterface;
        },
        GET_FILTER_TYPE(state) {
            return state.filterType;
        },
        GET_TAGS_LIST(state) {
            return state.tagsList.filter((item, index) => {
                return state.tagsList.indexOf(item) === index;
            });
        },
        GET_TAG_SEARCH_MODEL(state) {
            return state.tagSearchModel;
        },
        GET_TAG_COUNTER(state) {
            return state.tagCounter;
        },
        GET_GOOGLE_LOADER(state) {
            return state.googleLoader;
        },
        GET_TAGS_BAR(state) {
            return state.tagbar;
        }
    }
});
