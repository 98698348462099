import axios from "axios";

const path = "https://api.creative.gomobile.ru";
// const path = "https://apicreative.test.goaheadtech.net";
const get = async function(
    url: string,
    headerParam: string | null = null
    /*   heads: object | null = null, */
    //cacheParam: string | null = null
) {
    let response;
    /* if (heads) {
        // @ts-ignore
        heads.sameSite = "none";
    } */
    let web_url = `${path}/${url}`;
    if (url.includes("https")) {
        web_url = url;
    }
    try {
        // @ts-ignore
        response = await axios({
            method: "GET",
            url: web_url,
            //Delete before push
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            responseType: headerParam,
            withCredentials: true
        });
    } catch (er) {
        console.log("Request error: ", er);
        return { status: false, data: er };
    }
    return response.data;
};
const post = async function(url: string, data: object | null = null /* , headers: object | null = null */) {
    let response;
    /* if (headers) {
        // @ts-ignore
        headers.sameSite = "none";
    } */

    try {
        response = await axios.post(`${path}/${url}`, data, {  headers: {},  withCredentials: true });
    } catch (er) {
        console.log("Request error: ", er);
        return { status: false, data: er };
    }
    return response.data;
};
const put = async function(url: string, data: object | null = null /* headers = null */) {
    let response;
    /* if (headers) {
        // @ts-ignore
        headers.sameSite = "none";
    } */

    try {
        response = await axios.put(`${path}/${url}`, data, {  headers: {},  withCredentials: true });
    } catch (er) {
        console.log("Request error: ", er);
        return { status: false, data: er };
    }
    return response.data;
};
const deleteMet = async function(url: string, data: object | null = null /* headers = null */) {
    let response;
    try {
        response = await axios({
            method: "DELETE",
            url: `${path}/${url}`,
            data: data,
              headers: {},
            withCredentials: true
        });
    } catch (er) {
        console.log("Request error: ", er);
        return { status: false, data: er };
    }
    return response.data;
};
export default {
    get,
    post,
    put,
    deleteMet
};
