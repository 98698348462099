<template>
  <v-app class="main-background">
    <Navbar />
    <v-main class="mainView">
      <router-view></router-view>
    </v-main>
    <footer class="footer">
      <span class="footer-text"
        >Go Mobile © {{ new Date().getFullYear() }}</span
      >
    </footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>
<style lang="scss" >
@import "./assets/fonts/stylesheet.css";
@import "./assets/css/app.scss";
</style>
