<template >
  <div class="wrapper">
    <v-dialog v-model="dialog" max-width="540px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon :color="isActive" v-bind="attrs" v-on="on">
          <v-icon large>mdi-information-variant </v-icon>
        </v-btn>
      </template>
      <v-card class="dialogBox px-3 py-9">
        <h1 class="heading pb-0 text-center helper-header">
          Добро пожаловать в 
          Creative Approval System
        </h1>
        <p class="description mt-4">
          <b>Creative approval system</b> — сервис Go Mobile, который позволяет
          согласовывать креативы без коммуникации по электронной почте.
          Используйте комментарии и папки “Ждет согласования”, “Согласовано” и
          “Не согласовано”, чтобы утверждать или отправлять на доработку готовые
          рекламные материалы.
        </p>
        <p class="description">Поддерживаемые расширения креативов</p>
        <p class="description">
          <b>Для видео</b>: mp4 (H.264), webm (WebM), mkv
        </p>
        <p class="description"><b>Для фото</b>: GIF, JPEG, PNG, SVG</p>

        <v-spacer></v-spacer>

        <div class="text-center">
          <v-btn
            icon
            color="grey"
            @click="dialog = false"
            x-large
            absolute
            bottom
            class="btnAbsolute"
          >
            <v-icon x-large>mdi-close </v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    isActive() {
      return this.dialog ? "primary" : "grey";
    },
  },
};
</script>
<style lang="scss">
.dialogBox {
  min-height: 650px;
}
.btnAbsolute {
  transform: translateX(-50%);
}
.description {
  padding: 0 25px;
  margin-top: 10px;
}
@media screen and (max-width: 500px) {
  .dialogBox {
  min-height:auto;
}
  .helper-header{
    font-size: 22px;
  }
   .description{
     padding: 0 8px;
   }
}
</style>