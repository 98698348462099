<template>
  <div class="navWrapper">
    <div class="navbarGrad">
      <a
        v-if="!isAdmin"
        class="homeLink homeLinkAbs"
        href="https://client.entrance.gomobile.ru/"
      >
        <v-icon> mdi-home </v-icon>
        <span class="linkText hidden-sm-and-down">Сервисы</span></a
      ><a
        v-else
        class="homeLink homeLinkAbs"
        href="https://client.entrance.gomobile.ru/admin"
      >
        <v-icon> mdi-shield-account-outline </v-icon>
        <span class="linkText hidden-sm-and-down">В админ</span></a
      >
      <v-spacer></v-spacer>
      <div class="header-title">Creative Approval System</div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down btnLogout">
        <div v-if="isLogined" class="logoutClient" @click="logoutFunc">
          <v-icon> mdi-exit-to-app </v-icon>
          <span>Выйти</span>
        </div>
      </v-toolbar-items>
      <v-toolbar-items class="btnLogout">
        <Helper />
        <GoogleSinginBtn v-if="isAdmin" />
        <div class="d-flex">
          <ClientHelper />
          <router-link
            to="/admin"
            tag="a"
            class="homeLink homeRoute"
            v-if="!isAdmin && isgoogleLogined"
          >
            <span class="linkText hidden-sm-and-down">Админ</span>
            <v-icon> mdi-chevron-right </v-icon>
          </router-link>
        </div>
      </v-toolbar-items>
    </div>
  </div>
</template>

<script>
import GoogleSinginBtn from "@/components/GoogleSinginBtn.vue";
import ClientHelper from "@/components/ClientHelper";
import Helper from "@/components/Helper";
export default {
  components: {
    GoogleSinginBtn,
    ClientHelper,
    Helper,
  },
  data() {
    return {
      drawer: false,
      isgoogleLogined: false,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ava() {
      const urlava =
        localStorage.getItem("profileImage") ||
        "https://gomobile.ru/promo/dashboards/dashboard_meta.png";

      return urlava;
    },
    isLogined() {
      if (this.$route.path == "/") {
        return localStorage.getItem("user-token");
      }
    },
    isAdmin() {
      if (this.$route.path == "/admin") {
        return true;
      }
    },
  },
  methods: {
    initialize() {
      const googleLogined = this.getCookie("auth");
      if (googleLogined && googleLogined != "false") {
        this.isgoogleLogined = true;
      }
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          "(?:^|; )" +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
            "=([^;]*)"
        )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    logoutFunc() {
      location.reload(true);
      localStorage.removeItem("user-token");
      localStorage.removeItem("user");
    },
  },
};
</script>
<style lang="scss">
@import "../assets/css/navigation.scss";
</style>
